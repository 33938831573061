
import { mapGetters } from 'vuex';
import StelaLink from '~/components/common/StelaLink';
import BlackFridayModal from '~/components/modals/BlackFridayModal.vue';
import ChinaAddressModal from '~/components/modals/ChinaAddressModal';
import DelawareAddressChangedModal from '~/components/modals/DelawareAddressChangedModal';
import FEATURE_FLAG from '~/assets/constants/FEATURE_FLAG.js';
import OceanShippingCountDownModal from '~/components/modals/OceanShippingCountDownModal';
import StelaPageLayout from '~/components/layout/StelaPageLayout';
import StelaNotificationBanner from '~/components/panels/StelaNotificationBanner';
import StelaHeaderPanel from '~/components/panels/StelaHeaderPanel';
import StelaStoreIconPanel from '~/components/panels/StelaStoreIconPanel';
import StelaShippingInfoPanel from '~/components/panels/StelaShippingInfoPanel';
import StelaEasyShippingPanel from '~/components/panels/StelaEasyShippingPanel';
import StelaRecommendedReadingPanel from '~/components/panels/StelaRecommendedReadingPanel';
import StelaShippingPriceInfoPanel from '~/components/panels/StelaShippingPriceInfoPanel';
import StelaTestimonialPanel from '~/components/panels/StelaTestimonialPanel';
import StelaFaqPanel from '~/components/panels/StelaFaqPanel';
import SubscriptionPromoModal from '~/components/modals/SubscriptionPromoModal.vue';

export default {
  name: 'Home',
  components: {
    BlackFridayModal,
    ChinaAddressModal,
    StelaLink,
    DelawareAddressChangedModal,
    OceanShippingCountDownModal,
    StelaPageLayout,
    StelaNotificationBanner,
    StelaShippingInfoPanel,
    StelaHeaderPanel,
    StelaStoreIconPanel,
    StelaEasyShippingPanel,
    StelaShippingPriceInfoPanel,
    StelaRecommendedReadingPanel,
    StelaTestimonialPanel,
    StelaFaqPanel,
    SubscriptionPromoModal
  },
  data () {
    return {
      oceanShippingModalOpen: true,
    };
  },
  head () {
    return {
      title: this.pageHead?.title || 'Heroshe - Home',
      link: this.pageHead?.link || [],
      meta: this.pageHead?.meta || [],
      script: this.pageHead?.script
        ? this.pageHead?.script?.map(this.mapHeadScript)
        : [],
      __dangerouslyDisableSanitizers: ['script']
    };
  },
  computed: {
    FEATURE_FLAG () {
      return FEATURE_FLAG;
    },
    pageKey () {
      return 'home-page';
    },
    pageData () {
      return this.allPageData[this.pageKey] || {};
    },
    pageHead () {
      return this.pageData?.head || {};
    },
    hero () {
      return this.pageData?.hero || {};
    },
    heroBackgroundImageUrl () {
      return (
        this.getBackgroundImageUrl(this.hero) || this.hero?.backgroundImageUrl
      );
    },
    heroButtons () {
      return this.hero?.buttons || [];
    },
    storesSection () {
      return this.pageData?.compatibleStoresSection || {};
    },
    shippingInfoSection () {
      return this.pageData?.howToShipWithHerosheSection || {};
    },
    easyShippingSection () {
      return this.pageData?.weMakeItEasyForYouToShipSection || {};
    },
    shippingPriceCalculatorSection () {
      return this.pageData?.shippingPriceCalculatorSection || {};
    },
    testimonialsSection () {
      return this.pageData?.testimonialsSection || {};
    },
    faqSection () {
      return this.pageData?.faqSection || {};
    },
    ...mapGetters({
      allPageData: 'page/pageData',
      pageCountry: 'page/pageCountry',
      pageLocale: 'page/pageLocale'
    })
  },
  mounted () {
    // eslint-disable-next-line no-console
    console.log({
      country: this.pageCountry,
      locale: this.pageLocale
    });
  },
  methods: {
    openShowCountDownTimer () {
      this.oceanShippingModalOpen = false;
    },
  }
};


import { mapGetters, mapActions } from 'vuex';
import NewModal from '@/components/common/NewModal';

export default {
  name: 'BlackFridayModal',
  components: {
    NewModal,
  },
  data () {
    return {};
  },
  computed: {
    ...mapGetters({
      blackFriday: 'promotion/blackFriday',
    }),
  },
  methods: {
    closeModal (cache = true) {
      this.setPromotion({
        promotion: { key: 'blackFriday', value: false },
        cache,
      });
    },
    ...mapActions({ setPromotion: 'promotion/setPromotion' }),
  },
};



export default {
  name: 'CountDownTimer',
  components: {
  },
  props: {
  },
  data () {
    return {
      targetDate: this.$dayjs('2024-04-07'), // Set your target date
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
  },
  created () {
    setInterval(this.updateCountdown, 1000); // Update every second
    this.updateCountdown(); // Initial calculation
  },
  mounted () {
    this.updateCountdown();

    // Update countdown every second
    this.interval = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  },
  beforeDestroy () {
    // Clear interval when component is destroyed
    clearInterval(this.interval);
  },
  methods: {
    updateCountdown () {
      const now = this.$dayjs();
      const diff = this.targetDate.diff(now, 'seconds');

      this.countdown.days = Math.floor((diff % (7 * 24 * 3600)) / (24 * 3600));
      this.countdown.hours = Math.floor((diff % (24 * 3600)) / 3600);
      this.countdown.minutes = Math.floor((diff % 3600) / 60);
      this.countdown.seconds = diff % 60;
    },
  },
};

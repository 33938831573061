
import StelaPriceCalculatorPanel from '~/components/panels/StelaPriceCalculatorPanel';

export default {
  name: 'StelaShippingPriceInfoPanel',
  components: {
    StelaPriceCalculatorPanel
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      defaultText: 'They’re no hidden prices on Heroshe, enter the weight of your shipment to find out what you’ll pay',
    };
  },
  computed: {
    section () {
      return this.sectionData?.section || {};
    },
    text () {
      return this.sectionData?.text || this.defaultText;
    },
    moreInfoText () {
      return this.sectionData?.moreInfoText || '';
    },
    moreInfoLink () {
      return this.sectionData?.moreInfoLink || {};
    },
  },
};


export default {
  name: 'StelaEasyShippingPanel',
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      defaultContent: [
        {
          iconUrl: '/svg/track-and-manage-shipment.svg',
          title: 'Track and manage your shipments from anywhere',
          description: 'Ship and manage your packages effectively. Save time and money when you ship with Heroshe.'
        },
        {
          iconUrl: '/svg/stay-aware.svg',
          title: 'We keep you in the know with regular updates',
          description: 'Frequent communication on the status of your shipments. Consistent updates as soon as we receive your packages.'
        },
        {
          iconUrl: '/svg/easy-customer-service.svg',
          title: 'Great customer service for when you need help',
          description: 'Help when you need it. Our customer success team will promptly respond to your enquires.'
        }
      ],
    };
  },
  computed: {
    section () {
      return this.sectionData?.section || {};
    },
    content () {
      return this.sectionData?.content || this.defaultContent;
    },
  },
};


import StelaBouncingScrollDown from '~/components/panels/StelaBouncingScrollDown.vue';

export default {
  name: 'StelaStoreIconPanel',

  components: {
    StelaBouncingScrollDown,
  },

  props: {
    large: {
      type: Boolean,
      default: false
    },
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      showEnterAnimation: false,
      defaultStores: [
        {
          name: 'Amazon',
          url: '#',
          imageUrl: '/svg/store/store-icon7.svg',
        },
        {
          name: 'Apple',
          url: '#',
          imageUrl: '/svg/store/store-icon6.svg',
        },
        {
          name: 'Ebay',
          url: '#',
          imageUrl: '/svg/store/store-icon5.svg',
        },
        {
          name: 'Asos',
          url: '#',
          imageUrl: '/svg/store/store-icon4.svg',
        },
        {
          name: 'Adidas',
          url: '#',
          imageUrl: '/svg/store/store-icon3.svg',
        },
        {
          name: 'Zara',
          url: '#',
          imageUrl: '/svg/store/store-icon2.svg',
          style: 'margin-top: -2px;',
        },
        {
          name: 'Nike',
          url: '#',
          imageUrl: '/svg/store/store-icon1.svg',
        },
      ]
    };
  },
  computed: {
    section () {
      return this.sectionData?.section || {};
    },
    stores () {
      return this.sectionData?.stores || this.defaultStores;
    },
    allStoresLink () {
      return this.sectionData?.allStoresLink || {};
    },
  },
  mounted () {
    setTimeout(() => {
      this.showEnterAnimation = true;
    }, 100);
  },
};

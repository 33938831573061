
export default {
  name: 'StelaExpectShipmentPanel',
  methods: {
    submitSearch (e) {
      const { s } = Object.fromEntries(new FormData(e.target));
      if (s) { return this.$router.push(`/track-my-shipment?s=${s}`); }
      this.$router.push('/track-my-shipment');
    },
  },
};


import StelaLink from '~/components/common/StelaLink';

export default {
  name: 'BannerButtons',
  components: {
    StelaLink,
  },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    defaultButtonClass: {
      type: String,
      default: 'button',
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getButtonClass (button) {
      if (button.class) {
        return `${this.defaultButtonClass} ${button.class}`;
      }
      return this.defaultButtonClass;
    },
  },
};

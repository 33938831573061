
import { mapGetters, mapActions } from 'vuex';
import NewModal from '~/components/common/NewModal';
import CountDownTimer from '~/components/common/CountDownTimer';

export default {
  name: 'OceanShippingCountDownModal',
  components: {
    CountDownTimer,
    NewModal,
  },
  data () {
    return {};
  },
  computed: {
    ...mapGetters({
      oceanShippingCountDownModal: 'promotion/oceanShippingCountDownModal',
    }),
  },
  mounted () {},
  methods: {
    closeModal (cache = true) {
      this.setPromotion({
        promotion: { key: 'oceanShippingCountDownModal', value: false },
        cache,
      });
      this.$emit('open-show-count-down-timer');
    },
    ...mapActions({ setPromotion: 'promotion/setPromotion' }),
  },
};

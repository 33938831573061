
export default {
  name: 'StelaShippingInfoPanel',
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      defaultShippingInfo: [
        {
          iconUrl: '/svg/use-heroshe-address.svg',
          title: 'Use Heroshe address',
          description: 'Use the details on your dashboard to receive shipments within the US. Use online or drop off at the warehouse'
        },
        {
          iconUrl: '/svg/track-your-shipment.svg',
          title: 'Track your shipments',
          description: 'Use the details on your dashboard to receive shipments within the US. Use online or drop off at the warehouse'
        },
        {
          iconUrl: '/svg/deliver-anywhere.svg',
          title: 'Deliver anywhere you choose',
          description: 'Use the details on your dashboard to receive shipments within the US. Use online or drop off at the warehouse'
        }
      ],
    };
  },
  computed: {
    section () {
      return this.sectionData?.section || {};
    },
    shippingInfo () {
      return this.sectionData?.steps || this.defaultShippingInfo;
    },
    shippingInfoImageUrl () {
      return this.getImageUrl(this.sectionData) || this.sectionData?.imageUrl || '/svg/how-to-ship-with-heroshe.svg';
    },
  },
};


import SlideTransition from '~/components/transitions/SlideTransition';

export default {
  name: 'StelaFaq',
  components: {
    SlideTransition,
  },
  props: {
    isGrid: {
      type: Boolean,
      default: false
    },
    faqs: {
      type: Array,
      default: () => [
        {
          question: 'What are your shipping cost?',
          answer: 'We charge as low as $5 per kilogram for all shipments. Our minimum weight is 0.45 kilogram. Fragile items like TV and \n' +
            'glass furniture have an optional crating fee. Please see the full pricing page for all the rate details.',
        },
        {
          question: 'Are there additional or hidden charges?',
          answer: 'We charge as low as $5 per kilogram for all shipments. Please see the full pricing page for all the rate details.',
        },
        {
          question: 'How does the process work?',
          answer: `It works great as we charge as low as $5 per kilogram for all shipments. Please see the full pricing page for all the rate details.
                  We charge as low as $5 per kilogram for all shipments. Please see the full pricing page for all the rate details.`,
        },
      ]
    },
  },
  data () {
    return {
      activeQuestionIndex: this.isGrid ? -1 : 0,
    };
  },
  methods: {
    makeActive (itemIndex) {
      this.activeQuestionIndex = this.activeQuestionIndex === itemIndex ? null : itemIndex;
    },
    generateQuestionClasses (questionIndex) {
      return [
        'accordion__question',
        this.activeQuestionIndex === questionIndex
          ? 'accordion__question--active'
          : null
      ];
    },
  }
};

import { render, staticRenderFns } from "./StelaFaqPanel.vue?vue&type=template&id=50582fcc&scoped=true"
import script from "./StelaFaqPanel.vue?vue&type=script&lang=js"
export * from "./StelaFaqPanel.vue?vue&type=script&lang=js"
import style0 from "./StelaFaqPanel.vue?vue&type=style&index=0&id=50582fcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50582fcc",
  null
  
)

export default component.exports
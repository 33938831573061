
import BannerButtons from '~/components/common/BannerButtons';
import StelaExpectShipmentPanel from '~/components/panels/StelaExpectShipmentPanel';

export default {
  name: 'StelaHeaderPanel',
  components: {
    BannerButtons,
    StelaExpectShipmentPanel,
  },
  props: {
    backgroundUrl: {
      type: String,
      default: '/img/bg/bg-default.png',
    },
    backgroundDrop: {
      type: String,
      default: '/svg/logo/heroshe-christmas-drop.svg',
    },
    showSearchLabel: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
};

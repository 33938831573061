
import { mapGetters, mapActions } from 'vuex';
import Modal from '~/components/common/Modal';

export default {
  name: 'DelawareAddressChangedModal',
  components: {
    Modal,
  },
  data () {
    return {};
  },
  computed: {
    ...mapGetters({
      delawareAddressChangedModal: 'promotion/delawareAddressChangedModal',
    }),
  },
  methods: {
    closeModal (cache = true) {
      this.setPromotion({
        promotion: { key: 'delawareAddressChangedModal', value: false },
        cache,
      });
    },
    ...mapActions({ setPromotion: 'promotion/setPromotion' }),
  },
};

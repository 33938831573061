
import StelaFaq from '~/components/common/StelaFaq';

export default {
  name: 'StelaFaqPanel',
  components: {
    StelaFaq,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Frequently Asked Questions',
    },
    faqs: {
      type: Array,
      default: () => [
        {
          question: 'What are your shipping cost?',
          answer:
            'We charge as low as $5 per kilogram for all shipments. Our minimum weight is 0.45 kilogram. Fragile items like TV and \n' +
            'glass furniture have an optional crating fee. Please see the full pricing page for all the rate details.',
        },
        {
          question: 'Are there additional or hidden charges?',
          answer:
            'We charge as low as $5 per kilogram for all shipments. Please see the full pricing page for all the rate details.',
        },
        {
          question: 'How does the process work?',
          answer: `It works great as we charge as low as $5 per kilogram for all shipments. Please see the full pricing page for all the rate details.
                  We charge as low as $5 per kilogram for all shipments. Please see the full pricing page for all the rate details.`,
        },
      ],
    },
    isGrid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    section () {
      return this.sectionData?.section || {};
    },
    sectionFaqs () {
      return this.sectionData?.faqs || this.faqs;
    },
  },
};

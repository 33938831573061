
import StelaLink from '~/components/common/StelaLink';

export default {
  name: 'StelaTestimonialPanel',
  components: {
    StelaLink,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      currentSlide: 0,
      sliderOptions: {
        paginationEnabled: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        speed: 500,
        perPage: 1,
        adjustableHeight: false
      }
    };
  },
  computed: {
    section () {
      return this.sectionData?.section || {};
    },
    testimonials () {
      return this.sectionData?.testimonials || [];
    },
    moreStoriesText () {
      return this.sectionData?.moreStoriesText || '';
    },
    moreStoriesLink () {
      return this.sectionData?.moreStoriesLink || {};
    },
  },
  methods: {
    gotoSlide (slideIndex, advanceType) {
      this.$refs.testimonialCarousel.goToPage(slideIndex, advanceType);
      this.currentSlide = slideIndex;
    },
    setCurrentSlide (slideIndex) {
      this.currentSlide = slideIndex;
    }
  },
};


import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      isNotificationBanner: true,
    };
  },

  computed: {
    commonKey () {
      return 'notification-banner';
    },

    notificationBannerData () {
      return this.allCommonData?.[this.commonKey];
    },

    ...mapGetters({
      allCommonData: 'common/commonData',
    }),
  },

  methods: {
    closeNotificationBanner () {
      this.isNotificationBanner = false;
    },
  },
};

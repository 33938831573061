
export default {
  name: 'StelaRecommendedReadingPanel',
  data () {
    return {
      blogPosts: [],
    };
  },
  async fetch () {
    this.blogPosts = await this.$tera.fetchRecentlyPublishedBlogPosts();
  },
};
